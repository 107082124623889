#navWrapper {
  width: 100%;
  height: 60px;
  background-color: white;
  position: fixed;
  top: 0;
  z-index: 1000;
}

#navContent {
  height: 95%;
  display: flex;
  gap: 20px;
  align-items: center;
}

#accountitemswrapper {
  margin-left: auto;
  margin-right: 12px;
  display: flex;
  gap: 12px;
  align-items: center;
}

#title {
  font-family: "Roboto";
  font-weight: 100;
  margin-left: 15px;
  font-size: 30px;
  color: rgb(255, 110, 120);
}

.navitem {
  font-family: "Source Sans Pro";
  color: black;
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  position: relative;
  bottom: 0px;
  transition: bottom 0.1s;
}

.navitem:hover {
  bottom: 2px;
  border: rgb(153, 153, 153);
  border-width: 1px;
  border-bottom-style: solid;
}

#navitemupload {
  color: white;
}

#navitemupload:hover {
  bottom: 2px;
  border-bottom-style: none;
}

.accountitems {
  color: white;
  border-radius: 3px;
  padding: 7px 12px 7px 12px;
  background-color: rgb(255, 110, 120);
  border: none;
  outline: none;
}

#logout {
  border: none;
  background: none;
  outline: none;
  width: max-content;
  padding: 0;
}

#navsearchwrapper {
  display: flex;
  align-items: center;
}

#navSearchInput {
  background-color: rgb(236, 236, 236);
  border-color: rgb(236, 236, 236);
  border-style: solid;
  border-width: 1px;
  height: 40px;
  width: 200px;
  font-size: 12px;
  font-weight: 400;
  padding-left: 15px;
  transition: border 0.2s;
  outline: none;

  border-radius: 100px;
  color: rgb(49, 49, 49);
  font-family: "Cabin";
  transition: all 0.5s;
  display: grid;
  align-items: center;
  background-image: url("./Magnify.png");
  background-size: 19px 22px;
  background-repeat: no-repeat;
  background-position: left 7px center;
  text-indent: 20px;
}

#navSearchInput:focus {
  background-color: white;
  border-color: rgb(236, 236, 236);
}

#navSearchInput::placeholder {
  font-family: "Cabin";
  color: gray;
  font-weight: 100;
  font-size: 13px;
}

#navSearchButton {
  background: none;
  border: none;
  height: 38px;
  width: 38px;
  margin-left: 5px;
  position: relative;
  padding-bottom: 3px;
  transition: transform 0.5s;
  transform: rotate(0deg);
}

#navSearchButton:hover {
  transform: rotate(360deg);
}

#navSearchButtonIcon {
  color: rgb(39, 39, 39);
}

#navBurger {
  display: none;
}

@media screen and (max-width: 940px) {
  #navContent {
    display: none;
  }
}

@media screen and (max-width: 940px) {
  #navWrapper {
    background: none;
  }
  #navBurger {
    position: fixed;
    display: block;
    background: none;
    border: none;
    top: 0;
    left: 0;
    padding: 15px;
    outline: none;
    z-index: 0;
  }
  #title {
    margin-left: 0px;
    line-height: 1;
  }
  .navBurgerLine {
    width: 30px;
    height: 4px;
    background-color: black;
    margin-bottom: 5px;
    border: black;
    border-width: 2px;
    border-style: solid;
    border-radius: 5px;
  }
  .navItemLink {
    margin-bottom: 25px;
  }
  #navDropdown {
    position: fixed;
    top: 0;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: max-content;
    height: 100vh;
    transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
    left: 0;
  }
  #accountitemswrapper {
    display: flex;
    flex-direction: column;
    margin-left: 0px;
    align-items: flex-start;
  }
  #navsearchwrapper {
    order: 1;
    flex-direction: row-reverse;
  }
}
#navDropdown.hide {
  transform: translate3d(120vw, 0, 0);
}
#navDropdown.show {
  transform: translate3d(0vw, 0, 0);
}
