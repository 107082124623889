.mainColor {
  background-color: rgb(4, 228, 135);
}

#loginBody {
  height: 100vh;
  width: 100vw;
}

#loginFormWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding-top: 20px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 20px;
  border-radius: 10px;
}

#loginFormContainer {
  position: relative;
}

#loginHeader {
  font-family: "Roboto";
  font-size: 33px;
  margin-bottom: 20px;
  color: rgb(46, 46, 46);
  text-align: center;
  font-weight: 400;
}

#loginBar {
  width: 100%;
  height: 1px;
  background-color: rgb(231, 231, 231);
  margin-bottom: 20px;
}

.loginDescription {
  font-size: 15px;
  font-family: "Source Sans Pro";
  font-weight: 100;
  color: rgb(87, 87, 87);
  font-weight: 400;
  margin-bottom: 5px;
}

.loginInput {
  width: 320px;
  height: 45px;
  margin-bottom: 20px;
  border: rgb(241, 241, 241);
  outline: none;
  border-radius: 3px;
  font-family: "Source Sans Pro";
  font-size: 13px;
  color: rgb(110, 110, 110);
  padding: 10px;
  transition: border 0.5s, background-color 0.5s;
  background-color: rgb(240, 240, 240);
  box-sizing: border-box;
}

.loginInput:focus {
  background-color: rgb(255, 255, 255);
  border: rgb(156, 156, 156);
  border-style: solid;
  border-width: 1px;
  padding: 9px;
  box-sizing: border-box;
}

input:focus::placeholder {
  color: transparent;
}

.loginInput::placeholder {
  color: rgb(179, 179, 179);
  font-size: 13px;
}

#loginSubmitButton {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  outline: none;
  border-radius: 5px;
  border: none;
  text-decoration: none;
  color: white;
  width: 90px;
  height: 38px;
  position: relative;
  bottom: 0px;
  transition: bottom 0.1s;
}

#loginSubmitButton:hover {
  border: rgb(89, 212, 91);
  border-bottom-style: solid;
  border-width: 2px;
  bottom: 2px;
}

#loginError {
  color: red;
  margin-bottom: 5px;
  font-size: 14px;
}

#loginSubmitButton:active {
  background-color: rgb(108, 184, 109);
}

@media screen and (max-width: 500px) {
  #loginFormWrapper {
    padding-left: 15px;
    padding-right: 15px;
  }
}
