#resetWrapper {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.resetHeader {
  font-family: "Source Sans Pro";
  font-weight: 300;
  font-size: 35px;
}

.resetButton {
  border: none;
}

.resetInput {
  margin-bottom: 10px;
  width: 300px;
}

#resetPasswordButton {
  background: none;
  border: none;
  color: rgb(35, 113, 187);
  margin-bottom: 10px;
  font-family: "Source Sans Pro";
  font-size: 15px;
  font-weight: 300;
  line-height: 1;
  padding: 0;
  outline: none;
}
