.recipeBorderBox {
  border: rgb(236, 236, 236);
  border-style: solid;
  border-radius: 5px;
}

#recipeUserWrapper {
  margin-bottom: 30px;
}

#recipeUserCheck {
  font-family: "Source Sans Pro";
  font-weight: 300;
  font-size: 20px;
}

#recipeEditButton {
  margin-left: 10px;
  font-size: 15px;
  font-family: "Source Sans Pro";
  font-weight: 500;
  color: white;
  background-color: rgb(255, 110, 120);
  padding: 7px 15px;
  border-radius: 3px;
  position: relative;
  bottom: 0px;
  transition: bottom 0.1s;
  border: none;
}

#recipeEditButton:hover {
  color: white;
  bottom: 2px;
  text-decoration: none;
  border: rgb(179, 179, 179);
  border-bottom-style: solid;
  border-width: 1px;
}

#recipeDeleteButton {
  margin-left: 10px;
  font-size: 15px;
  font-family: "Source Sans Pro";
  font-weight: 500;
  color: white;
  background-color: rgb(255, 110, 120);
  padding: 5px 10px;
  border-radius: 3px;
  position: relative;
  bottom: 1px;
  transition: bottom 0.1s;
  border: none;
}

#recipeDeleteButton:hover {
  color: white;
  bottom: 3px;
  text-decoration: none;
  border: rgb(179, 179, 179);
  border-bottom-style: solid;
  border-width: 1px;
}

#recipePhotoContent {
  width: 500px;
  height: 300px;
  display: grid;
  align-items: center;
  justify-content: center;
}

#recipePhoto {
  max-height: 280px;
}

#recipeFactsWrapper {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 70px;
}

.recipeFactsHeader {
  font-family: sans-serif;
  font-size: 23px;
  font-weight: 300;
  line-height: 1;
}

.recipeFactsText {
  font-family: Helvetica;
  font-size: 20px;
  font-weight: 300;
  color: rgb(49, 49, 49);
}

#recipeTitleWrapper {
  display: flex;
  align-items: center;
}

#recipeTitle {
  font-family: Helvetica;
  font-size: 55px;
  font-weight: 600;
  color: rgb(56, 56, 56);
  line-height: 1;
}

#recipeTitleDivider {
  width: 1px;
  height: 60px;
  background-color: rgb(212, 212, 212);
  margin-left: 20px;
}

#recipeLikesWrapper {
  display: grid;
  justify-items: center;
  margin-left: 10px;
}

#recipeLikeButton {
  background-color: white;
  border: none;
  outline: none;
}

#recipeLikeButton:hover {
  color: rgb(49, 49, 49);
}

#recipeLikeButton:active {
  color: rgb(91, 93, 255);
}

#recipeLikeIcon {
  width: 35px;
  height: 35px;
  margin-bottom: 10px;
}

.recipeHeader {
  font-family: Helvetica;
  font-weight: 500;
  font-size: 28px;
  color: rgb(56, 56, 56);
}

#recipeCreator {
  font-family: Helvetica;
  font-size: 17px;
  font-weight: 100;
  line-height: 1;
  color: rgb(90, 90, 90);
  margin-bottom: 30px;
}

#recipeDescription {
  font-family: Helvetica;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  color: rgb(37, 37, 37);
}

#recipeSetupWrapper {
  grid-column: 1/3;
}

#recipeInfoPreps {
  display: grid;
  grid-template-columns: 0.5fr 0.5fr;
  grid-column-gap: 20px;
}

#recipePrepItemWrapper {
  padding-left: 20px;
}

.recipePrepItem {
  font-family: "Source Sans Pro";
  font-weight: 300;
  font-size: 22px;
}

#recipeIngredientsItemWrapper {
  padding-left: 20px;
}

#recipeCookingHeader {
  font-size: 42px;
}

#recipeCookingItemsWrapper {
  padding-left: 20px;
}

.recipeCookingItem {
  font-family: "Source Sans Pro";
  font-weight: 300;
  font-size: 27px;
  margin-bottom: 10px;
}

#recipeCommentBarrier {
  width: 100%;
  height: 2px;
  background-color: rgb(212, 212, 212);
}

#recipeCommentHeader {
  margin-top: 10px;
}

#recipeCommentInput {
  border: none;
  margin-bottom: 5px;
  width: 500px;
  outline: none;
}

.recipeCommentBorder {
  width: 500px;
  height: 2px;
  background-color: rgb(212, 212, 212);
}

#recipeCommentSubmit {
  margin-top: 5px;
  border: none;
  margin-right: 10px;
  background-color: rgb(131, 226, 133);
}

#recipeCommentSubmit:hover {
  background-color: rgb(112, 192, 113);
}

.recipeCommentButton {
  border: none;
  height: 30px;
  font-family: "Source Sans Pro";
  border-radius: 3px;
}

.recipeCommentButton:hover {
  background-color: rgb(182, 182, 182);
}

#recipeCommentItemWrapper {
  margin-top: 10px;
}

#recipeCommentItemContent {
  display: grid;
  grid-template-columns: max-content 1fr;
  margin-bottom: 10px;
  align-items: center;
}

#recipeCommentItemUserWrapper {
  display: flex;
  align-items: center;
}

#recipeCommentItemUser {
  font-family: "Source Sans Pro";
  font-weight: 300;
  font-size: 18px;
}

#recipeCommentItemProfile {
  width: 40px;
  height: 40px;
  border-width: 1px;
  border-radius: 50px;
  border: rgb(236, 236, 236);
  border-style: solid;
  margin-left: 5px;
}

#recipeCommentItem {
  font-family: "Source Sans Pro";
  font-weight: 300;
  font-size: 20px;
}

#recipeCommentItemDivider {
  width: 1px;
  height: 35px;
  background-color: rgb(190, 190, 190);
  margin-right: 5px;
  margin-left: 5px;
}

#recipeWrapper {
  margin-top: 120px;
  left: 50%;
  transform: translate(-50%);
  position: absolute;
}

#recipeContent {
  display: grid;
  grid-template-columns: 500px 600px;
  grid-auto-rows: max-content;
  grid-row-gap: 30px;
  grid-column-gap: 100px;
}

#recipeCookingWrapper {
  grid-column: 1/3;
}

#recipeCommentsWrapper {
  grid-column: 1/3;
  margin-bottom: 100px;
}

@media screen and (max-width: 1260px) {
  #recipeContent {
    grid-template-columns: 400px 400px;
  }
}

@media screen and (max-width: 950px) {
  #recipeContent {
    grid-template-columns: 600px;
  }
  #recipePhotoWrapper {
    grid-row: 2/3;
  }
  #recipeCookingWrapper {
    grid-row: 3/4;
    grid-column: 1/2;
  }
  #recipeCommentsWrapper {
    grid-row: 4/5;
    grid-column: 1/2;
  }
}

@media screen and (max-width: 600px) {
  #recipeContent {
    grid-template-columns: 350px;
  }
}
