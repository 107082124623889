#uploadHubWrapper {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  text-align: center;
}

.uploadHubHeader {
  font-family: "Source Sans Pro";
  font-weight: 900;
  font-size: 50px;
  background-color: rgb(136, 228, 138);
  padding: 5px;
  border-radius: 2px;
  color: white;
}

.uploadHubLink {
  background-color: rgb(136, 228, 138);
  padding: 5px;
  color: white;
  font-family: "Source Sans Pro";
  font-weight: 300;
  font-size: 35px;
  text-decoration: none;
  border-radius: 5px;
  box-shadow: 0px 0px 2px rgb(197, 197, 197);
}

.uploadHubLink:hover {
  background-color: rgb(115, 189, 116);
}

#uploadHubLinkWrapper {
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
  margin-top: 20px;
}

@media screen and (max-width: 1080px) {
  #uploadManualBar {
    visibility: hidden;
  }
}
@media screen and (max-width: 730px) {
  #uploadManualBar {
    visibility: hidden;
  }
}
