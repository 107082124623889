#family-nav-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  transition: all 0.5s;
  transition-delay: 0.5s;
}

.family-nav-button {
  background-color: #ff7e36;
  width: 150px;
  height: 50px;
  border-radius: 50px;
  font-family: "Cabin";
  font-size: 12px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
}

.family-nav-button:hover {
  background-color: #e66e2e;
}

#family-nav-collapsed {
  border: none;
  outline: none;
  display: flex;
  align-content: center;
  justify-content: left;
  position: fixed;
  bottom: 10vh;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 100px;
  box-shadow: 0px 0px 10px rgb(212, 212, 212);
  transition: all 0.5s;
}

.family-line {
  border-radius: 100px;
  background-color: rgb(104, 104, 104);
  width: 23px;
  transition: all 0.5s;
}

#family-line-3 {
  margin-bottom: 0px;
}

@media screen and (max-width: 800px) {
}
