#myrecipesWrapper {
  display: flex;
  justify-content: center;
}

#myrecipesContent {
  margin-top: 100px;
  border: rgb(226, 226, 226);
  border-style: solid;
  border-radius: 4px;
  border-width: 2px;
  width: 90%;
  height: max-content;
}

#myrecipesShare {
  font-family: "Source Sans Pro";
  font-weight: 300;
}

#myrecipeUploadButton {
  background-color: rgb(255, 110, 120);
  border: none;
  color: white;
  font-family: "Source Sans Pro";
  font-weight: 300;
  font-size: 17px;
  position: relative;
  bottom: 0px;
  transition: bottom 0.1s;
  padding: 5px;
  border-radius: 3px;
  text-align: center;
}

#myrecipeUploadButton:hover {
  border: rgb(207, 88, 96);
  border-width: 2px;
  border-bottom-style: solid;
  bottom: 2px;
  text-align: center;
  text-decoration: none;
}

#myrecipeNoneWrapper {
  display: grid;
  justify-content: center;
  margin-top: 120px;
}

#myrecipeText {
  font-family: "Source Sans Pro";
  font-weight: 300;
  font-size: 22px;
}
