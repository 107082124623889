.searchFilterHeader {
  margin-top: 20px;
  font-family: "Roboto";
  font-weight: 300;
  font-size: 20px;
}

.searchFilterCheckWrapper {
  display: flex;
  align-items: center;
}

.searchFilterCheckbox {
  width: 50px;
  height: 50px;
  background-color: aqua;
}

.searchFilterLabel {
  font-family: "Source Sans Pro";
  font-weight: 300;
  font-size: 20px;
}

#searchFilterWrapper {
  height: 100vh;
}

#searchFilterContent {
  margin-left: 30px;
}

#searchFilterTitle {
  font-family: "Source Sans Pro";
  font-weight: 300;
  font-size: 30px;
}

#searchFilterShareWrapper {
  margin-top: 50px;
}

#searchFilterIngredientInputWrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}

#searchFilterIngreidentInput {
  border: rgb(219, 219, 219);
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
  outline: none;
  font-family: "Source Sans Pro";
  height: 30px;
  width: 200px;
  font-size: 15px;
}

#searchFilterIngreidentInput:focus {
  border: rgb(184, 184, 184);
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
}

.searchFilterIngredientButton {
  border: none;
  border-radius: 50px;
  background-color: rgb(136, 228, 138);
  color: white;
  width: 25px;
  height: 25px;
  font-size: 30px;
  display: grid;
  justify-content: center;
  align-content: center;
}

.searchFilterIngredientButton:hover {
  background-color: rgb(114, 194, 115);
}

#searchFilterIngredientAddIcon {
  margin-bottom: 1px;
  font-family: "Source Sans Pro";
  font-weight: 300;
}

#searchFilterIngredientRemoveIcon {
  margin-bottom: 5px;
  font-family: "Source Sans Pro";
  font-weight: 300;
}
