#editWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
}

#editContentWrapper {
  margin-top: 100px;
  background-color: white;
  max-width: 1000px;
  width: 90%;
  display: grid;
  justify-content: center;
}

#editContent {
  margin-top: 50px;
}
