.createFamThinText {
  font-family: "Source Sans Pro";
  font-weight: 300;
}

.createFamBoldText {
  font-family: Helvetica;
  font-weight: 600;
}

.createFamInput {
  width: 280px;
  height: 50px;
  border: rgb(245, 245, 245);
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
  padding-left: 15px;
  font-size: 15px;
  color: rgb(48, 48, 48);
  outline: none;
  transition: border 0.2s;
}

.createFamInput:focus {
  border: gray;
  border-width: 1px;
  border-style: solid;
}

.createFamInput::placeholder {
  font-size: 17px;
  font-weight: 200;
  color: rgb(165, 165, 165);
}

#createFam {
  height: 100vh;
  width: 100vw;
}

#createFamWrapper {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  border-radius: 5px;
  max-width: 1000px;
  width: 90vw;
  height: max-content;
  background-color: white;
  padding: 20px;
}

#createFamContent {
  display: grid;
  justify-content: center;
  text-align: center;
  position: relative;
}

#createFamIntro {
  font-size: 35px;
}

#familyChoiceOrder {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 40px;
}

.createFamilyChoiceWrapper {
  border-radius: 5px;
  border-width: 1px;
  border: rgb(241, 241, 241);
  border-style: solid;
  max-width: 250px;
  height: max-content;
  position: relative;
  text-align: center;
  background-color: white;
}

#createFamilyChoiceFamily {
  font-size: 26px;
}

#createFamilyChoiceIdentifier {
  color: gray;
}

.createFamilyButton {
  margin-top: 30px;
  padding: 5px;
  background-color: rgb(136, 228, 138);
  border: none;
  border-width: 1px;
  font-family: "Source Sans Pro";
  font-size: 23px;
  font-weight: 200;
  color: white;
  border-radius: 3px;
  bottom: 0px;
  transition: bottom 0.1s;
  position: relative;
  outline: none;
}

.createFamilyButton:hover {
  bottom: 1px;
  border-width: 1px;
  border: rgb(116, 189, 117);
  border-bottom-style: solid;
}

#createFamilyOR {
  margin-top: 20px;
  font-size: 37px;
}

#createFamilyJoin:hover {
  background-color: rgb(116, 189, 117);
}

#createFamilyJoin:active {
  border: black;
  border-style: solid;
  border-width: 1px;
}

#createFamilyBottom {
  display: flex;
  justify-content: center;
  gap: 50px;
  align-items: center;
  text-align: start;
}

#createFamilyBottomSeperator {
  width: 1px;
  height: 150px;
  background-color: rgb(219, 219, 219);
}

#createFamilyBottomButton {
  margin-top: 10px;
  width: 90px;
  padding: 2px;
  font-size: 20px;
}

#createFamSearchWrapper {
  margin-top: 10px;
}

#createFamSearch {
  font-size: 20px;
  padding: 5px 20px;
}

#createFamSearch:hover {
  color: white;
  text-decoration: none;
}

#searchFamilyWrapper {
  width: 100%;
  height: 100vh;
  display: grid;
  place-content: center;
}

#searchFamilyContent {
  background-color: white;
  padding: 30px;
  width: 90vw;
}

#searchFamilyGroup {
  display: grid;
  grid-auto-rows: max-content;
  grid-template-columns: repeat(auto-fit, 250px);
}

.searchFamilyItem {
  margin: 10px;
}

#searchFamilyNullWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

#searchFamilyNull {
  font-family: Helvetica;
  font-size: 25px;
  font-weight: 600;
  color: rgb(37, 37, 37);
}

.searchFamilyJoinTitle {
  font-family: "Source Sans Pro";
  font-weight: 300;
  font-size: 20px;
}

@media screen and (max-width: 800px) {
  #familyChoiceOrder {
    display: grid;
    justify-content: center;
    gap: 10px;
  }
}
