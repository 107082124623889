#signupBody {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
}

#signupFormWrapper {
  position: absolute;
  margin-top: 200px;
  background-color: white;
  padding-top: 20px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 20px;
  border-radius: 10px;
}

#signupFormContainer {
  position: relative;
}

#signupHeader {
  font-family: "Roboto";
  font-size: 35px;
  margin-bottom: 20px;
  color: rgb(87, 87, 87);
  text-align: center;
  font-weight: 200;
}

#signupBar {
  width: 100%;
  height: 1px;
  background-color: rgb(231, 231, 231);
  margin-bottom: 20px;
}

.signupDescription {
  font-size: 15px;
  font-family: "Source Sans Pro";
  font-weight: 100;
  color: rgb(87, 87, 87);
  font-weight: 400;
  margin-bottom: 5px;
}

.signupInput {
  width: 320px;
  height: 45px;
  margin-bottom: 20px;
  border: rgb(241, 241, 241);
  border-style: solid;
  border-radius: 3px;
  border-width: 1px;
  outline: none;
  font-family: "Source Sans Pro";
  font-size: 13px;
  color: rgb(110, 110, 110);
  padding-left: 10px;
  transition: border 0.2s;
}

.signupInput:focus {
  border: rgb(155, 155, 155);
  border-style: solid;
  border-width: 1px;
}

.signupInput::placeholder {
  color: rgb(179, 179, 179);
  font-size: 13px;
}

#signupSubmitButton {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  outline: none;
  border-radius: 10px;
  border: none;
  text-decoration: none;
  background-color: rgb(136, 228, 138);
  color: white;
  width: 100px;
  height: 40px;
  bottom: 0px;
  position: relative;
  transition: bottom 0.1s;
}

#signupSubmitButton:hover {
  border: rgb(108, 184, 109);
  border-width: 2px;
  border-bottom-style: solid;
  bottom: 1px;
}

#signupSubmitButton:active {
  background-color: rgb(108, 184, 109);
}

#signupStrengthWrapper {
  margin-bottom: 10px;
}

#signupStrength {
  height: 10px;
  border: none;
}

#signupStrength::-moz-progress-bar {
  background: rgb(255, 110, 120);
}

#signupStrengthText {
  line-height: 1;
  font-family: "Source Sans Pro";
  font-weight: 300;
  font-size: 15px;
}

@media screen and (max-width: 500px) {
  #signupFormWrapper {
    margin-top: 75px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
