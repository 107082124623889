.family-member-wrapper {
  display: grid;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 170px;
  height: 220px;
  border-radius: 30px;
  margin: 7px;
}

.family-member-image {
  width: 100px;
  height: 100px;
  background-color: #ff7e36;
  border-radius: 50%;
  margin-bottom: 15px;
}

.family-member-name {
  font-size: 22px;
  font-family: "Cabin";
  color: #ff7e36;
  font-weight: 900;
  margin-bottom: 10px;
  line-height: 1;
}

.family-member-role {
  font-family: "Cabin";
  font-size: 15px;
  font-weight: 300;
  color: black;
}
