#uploadManualWrapper {
  display: grid;
  justify-items: center;
}

#uploadManualContent {
  padding-top: 50px;
  margin-top: 100px;
  background-color: white;
  max-width: 1000px;
  width: 90vw;
  display: grid;
  justify-content: center;
  align-content: flex-start;
  position: relative;
  border-radius: 20px;
  box-shadow: -2px 5px 20px 1px rgba(224, 224, 224, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#uploadInnerContent {
  max-width: 700px;
  width: 80vw;
}

#uploadManualTitleWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  margin-top: 10px;
}

#uploadManualTitle {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 50px;
  line-height: 1;
  color: rgb(56, 56, 56);
}
.uploadManualRequired {
  color: red;
}

#uploadManualPhoto {
  visibility: hidden;
  position: absolute;
}

#uploadManualPhoto + label {
  background-color: rgb(131, 226, 133);
  background-color: rgb(131, 226, 133);
  border: none;
  color: white;
  font-style: "Source Sans Pro";
  font-weight: 300;
  font-size: 25px;
  width: max-content;
  padding: 10px;
  line-height: 1;
  border-radius: 3px;
  margin-top: 5px;
  cursor: pointer;
}

#uploadManualPhoto + label:hover {
  background-color: rgb(100, 173, 101);
}

.uploadManualButton {
  background-color: rgb(131, 226, 133);
  border: none;
  color: white;
  font-style: "Source Sans Pro";
  font-weight: 300;
  font-size: 25px;
  width: 110px;
  padding: 5px;
  line-height: 1;
  border-radius: 3px;
  margin-top: 5px;
}

.uploadManualButton:hover {
  background-color: rgb(118, 207, 119);
}

.uploadManualInputWrapper {
  font-family: "Source Sans Pro";
  font-weight: 200;
  font-size: 24px;
  margin-top: 20px;
}

.uploadManualInput {
  height: 50px;
  background-color: rgb(240, 240, 240);
  border: rgb(240, 240, 240);
  border-style: solid;
  border-radius: 10px;
  border-width: 1px;
  padding: 10px;
  color: rgb(56, 56, 56);
  font-size: 16px;
  display: inline-block;
  outline: none;
  transition: 0.2s;
  resize: horizontal;
  width: 100%;
}

.uploadManualInput:focus {
  background-color: white;
}

.uploadManualInput::placeholder {
  font-weight: 200px;
  font-family: "Source Sans Pro";
  font-size: 16px;
  color: rgb(158, 158, 158);
}

#uploadManualInputDescription {
  height: 200px;
}

#uploadManualInputTags {
  width: 300px;
}

.uploadManualRemove {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  background-color: rgb(255, 56, 56);
  display: inline-block;
  position: relative;
  bottom: 15px;
  border: none;
  outline: none;
  margin-left: 5px;
  margin-top: 20px;
}

.uploadManualRemove:hover {
  background-color: rgb(206, 47, 47);
}

.uploadManualRemoveInner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 13px;
  height: 2px;
  background-color: rgb(255, 255, 255);
}

.uploadManualAdd {
  margin-top: 20px;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  display: block;
  background-color: rgb(131, 226, 133);
  position: relative;
  bottom: 15px;
  border: none;
  outline: none;
  margin-left: 5px;
}

.uploadManualAdd:hover {
  background-color: rgb(113, 197, 114);
}

.uploadManualAddInner1 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 2px;
  height: 13px;
  background-color: rgb(255, 255, 255);
}

.uploadManualAddInner2 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 13px;
  height: 2px;
  background-color: rgb(255, 255, 255);
}

#uploadManualSave {
  margin-bottom: 50px;
  height: 50px;
  width: 150px;
  bottom: 0px;
  position: relative;
  transition: bottom 0.2s;
  outline: none;
  overflow: hidden;
  box-shadow: 6px 7px 40px -4px rgba(0, 0, 0, 0.2);
}

#uploadManualSave:hover {
  bottom: 2px;
  border: rgb(102, 173, 103);
  border-bottom-style: solid;
  border-width: 2px;
}

.uploadManualShareButton {
  background-color: rgb(131, 226, 133);
  border: rgb(131, 226, 133);
}

#uploadManualShare {
  margin-bottom: 50px;
}

#uploadManualBottom {
  height: 50px;
  width: 100%;
}

#uploadManualReturn {
  width: 130px;
  text-align: center;
  margin-bottom: 50px;
}

#uploadManualReturn:hover {
  color: white;
  text-decoration: none;
}

#uploadManualConfirmed {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#uploadRedirectText {
  font-family: "Source Sans Pro";
  font-weight: 300;
  font-size: 25px;
}

@media screen and (max-width: 1000px) {
  #uploadManualTitleWrapper {
    display: grid;
  }
  #uploadManualContent {
    padding: 20px;
    justify-content: left;
  }
}
