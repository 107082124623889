#familyNavWrapper {
  width: 300px;
  height: 100vh;
  background-color: rgb(247, 247, 247);
  position: fixed;
  z-index: 0;
  left: 0px;
  top: 60px;
  text-align: center;
}

#familyRequestLinkWrapper {
  position: absolute;
  right: 20px;
  top: 80px;
}

#familyRequestLink {
  font-family: "Source Sans Pro";
  background-color: white;
  color: black;
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-size: 15px;
  font-weight: 300;
  padding: 9px 15px;
  border-radius: 200px;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

#familyRequestLink:hover {
  color: white;
  background-color: rgb(0, 0, 0);
}

#familyRequestNumbers {
  background-color: red;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 15px;
  right: 10px;
  font-size: 15px;
  color: white;
}

#familyRequestItem {
  font-family: "Source Sans Pro";
  font-weight: 900;
  font-size: 20px;
  display: flex;
  gap: 20px;
  align-items: center;
  margin-top: 20px;
  margin-left: 50px;
}

#familyRequestHeader {
  font-weight: 500;
  margin-right: -15px;
}

.familyNavItemWrapper {
  margin-bottom: 50px;
}

.familyNavItem {
  font-family: "Source Sans Pro";
  font-weight: 300;
  font-size: 20px;
  background-color: rgb(250, 106, 106);
  color: white;
  padding: 10px;
  border-radius: 3px;
  bottom: 0px;
  position: relative;
  transition: bottom 0.2s;
}

.familyNavItem:hover {
  bottom: 2px;
  border-width: 1px;
  border: rgb(196, 82, 82);
  border-bottom-style: solid;
  text-decoration: none;
  color: white;
}

.familyNavHeader {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-size: 50px;
  color: rgb(250, 106, 106);
  margin: 20px;
}

#familyNavBack {
  position: absolute;
  bottom: 0px;
  width: 100%;
  margin-bottom: 100px;
}

#familyContentWrapper {
  height: 100vh;
  display: grid;
  justify-content: center;
  align-content: center;
}

.familyTitle {
  font-family: "Cabin";
  font-weight: 900;
  font-size: 40px;
  color: rgb(68, 68, 68);
  line-height: 1;
}

#familyName {
  font-family: "Cabin";
  font-weight: 900;
  color: #ff7e36;
  font-size: 100px;
  line-height: 1;
}

.familyHeader {
  font-family: "Cabin";
  font-weight: 900;
  font-size: 30px;
  color: rgb(68, 68, 68);
  line-height: 1;
}

#familyDescription {
  font-family: "Source Sans Pro";
  font-weight: 300;
  font-size: 40px;
  margin-top: 20px;
}

#famInvWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Source Sans Pro";
  font-size: 70px;
  width: 90%;
  text-align: center;
  font-weight: 700;
}

#famInvReturn {
  font-size: 40px;
  color: black;
  padding: 10px;
  border-radius: 10px;
  background-color: rgb(136, 228, 138);
}

#famInvFailed {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 50px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

#famSetInvWrapper {
  justify-content: center;
  display: grid;
  margin: 200px;
  justify-items: center;
}

.famSetInvHeader {
  font-family: "Source Sans Pro";
  font-weight: 300;
  font-size: 40px;
}

.famSetInvGenerate {
  font-family: "Source Sans Pro";
  font-weight: 300;
  font-size: 20px;
  background-color: rgb(250, 106, 106);
  color: white;
  padding: 9px;
  border-radius: 3px;
  bottom: 0px;
  position: relative;
  border: none;
  transition: bottom 0.2s;
  margin-top: 20px;
  margin-bottom: 10px;
}

.famSetInvGenerate:hover {
  bottom: 2px;
  border-width: 1px;
  border: rgb(196, 82, 82);
  border-bottom-style: solid;
  color: white;
  padding: 8px;
}

#famSetInvLink {
  width: 500px;
  word-wrap: break-word;
  padding: 20px;
  background-color: rgb(247, 247, 247);
  color: black;
  margin-top: 20px;
  border-radius: 20px;
  font-size: 20px;
  font-family: "Source Sans Pro";
  font-weight: 300;
}

#famSetInvCopy {
  background-color: rgb(250, 106, 106);
  margin-top: 5px;
  color: white;
  outline: none;
  border: none;
  font-family: "Source Sans Pro";
  border-radius: 5px;
  padding: 5px;
  transition: background-color 0.2s;
}

#famSetInvCopy:hover {
  background-color: rgb(197, 82, 82);
}

#familyMemberWrapper {
  display: grid;
  justify-items: center;
  margin-top: 200px;
  font-family: "Source Sans Pro";
  font-weight: 300;
  font-size: 30px;
}

.familyMemberHeader {
  line-height: 1;
  font-weight: 800;
  font-family: "Cabin";
  font-size: 40px;
  color: black;
  margin-bottom: 30px;
}

.familyMemberItem {
  margin-top: 30px;
}

#family-member-content {
  border-radius: 40px;
  margin-top: 40px;
  background-color: #efefef;
  max-width: 800px;
  width: 90%;
  min-height: 500px;
  height: max-content;
  display: flex;
  flex-wrap: wrap;

  padding: 30px;
}

@media screen and (max-width: 500px) {
  #famInvWrapper {
    font-size: 50px;
  }
  #famInvReturn {
    font-size: 25px;
  }
}
