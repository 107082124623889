html,
body {
  margin: 0px;
}

a {
  text-decoration: none;
}

.text {
  font-family: "Cabin";
  font-weight: 900;
  font-size: 20px;
  line-height: 1;
  color: black;
}

.button {
  font-family: "Source Sans Pro";
  font-size: 20px;
  border-style: solid;
  border-color: rgb(136, 228, 138);
  padding: 10px 30px;
  border-radius: 100px;
  border-width: 2px;
  color: black;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  text-decoration: none;
}

.button:hover {
  background-color: rgb(136, 228, 138);
  color: white;
}

.seperator {
  background-color: black;
  width: 100px;
  height: 2px;
}

#backgroundImage {
  background-image: url("./photos/index/WBC_7095.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  height: 100vh;
  display: grid;
  justify-items: center;
  align-content: center;
  z-index: -1;
}

#mainTitle {
  font-family: "Cabin";
  font-weight: 900;
  font-size: 250px;
  color: white;
  text-shadow: 0px 0px 5px rgb(80, 80, 80);
}

.mainButton {
  background: none;
  width: max-content;
  padding: 10px 50px;
  border-color: rgb(255, 255, 255);
  border-style: solid;
  font-family: "Source Sans Pro";
  font-weight: 700;
  border-radius: 200px;
  color: black;
  background-color: white;
  font-size: 20px;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  margin-right: 20px;
}

.mainButton:hover {
  background-color: #da5f1d;
  color: white;
  border-color: #da5f1d;
}

.recipeCardLink {
  color: black;
  text-decoration: none;
  position: relative;
  bottom: 0;
  transition: bottom 0.2s;
  width: max-content;
  outline: none;
}

.recipeCardLink:hover {
  color: black;
  text-decoration: none;
  bottom: 5px;
}

#mainPageSecondWrapper {
  height: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#mainPageThirdWrapper {
  height: 1000px;
  display: flex;
  justify-content: center;
}

#mainPageThirdContent {
  display: flex;
  height: max-content;
  align-items: center;
  gap: 50px;
}

.mainHeader {
  font-family: "Open Sans";
  font-weight: 100;
  font-size: 40px;
  color: rgb(44, 44, 44);
}

#mainImage1 {
  width: 500px;
  height: 600px;
  box-shadow: -5px 5px 8px 1px rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
}

@media screen and (max-width: 920px) {
  #mainTitle {
    font-size: 100px;
  }
  .mainHeader {
    font-size: 40px;
  }
  #mainPageSecondWrapper {
    display: flex;
    justify-content: center;
  }
  #mainPageSecondText {
    width: 90%;
  }
  #mainPageThirdWrapper {
    position: absolute;
    left: 50%;
    width: 90%;
    transform: translate(-50%);
  }
  #mainPageThirdContent {
    display: grid;
  }

  #mainImage1 {
    max-width: 500px;
    width: 100%;
    height: auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
}
