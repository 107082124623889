#searchContent {
  display: grid;
  grid-template-columns: 300px 1fr 10px;
  grid-template-rows: 60px 100px 1fr;
}

#searchContentWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, 290px);
  grid-auto-rows: max-content;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  margin-left: 20px;
  border: rgb(240, 240, 240);
  border-style: solid;
  border-radius: 5px;
  padding: 10px;
  grid-row: 3/4;
}

#searchItemWrapper {
  border: rgb(228, 228, 228);
  border-style: solid;
  border-width: 2px;
  border-radius: 5px;
  display: grid;
  grid-template-rows: 70px 170px 100px;
  width: 290px;
  padding: 10px;
  justify-content: center;
}

#searchItemTitle {
  font-family: "Source Sans Pro";
  font-weight: 900;
  font-size: 25px;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#searchItemImage {
  max-width: 240px;
  height: 100%;
  justify-self: center;
}

#searchItemDescription {
  font-family: "Source Sans Pro";
  font-weight: 300;
  font-size: 18px;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#searchItemLikes {
  margin-top: 10px;
}
