#aboutWrapper {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
}

#aboutContent {
  width: 1000px;
}

.aboutHeader {
  font-family: Helvetica;
  font-weight: 600;
  font-size: 50px;
  color: rgb(51, 51, 51);
  margin-bottom: 10px;
}

.aboutText {
  font-family: "Source Sans Pro";
  font-weight: 300;
  font-size: 20px;
}
