#dashboard-wrapper {
  width: 100%;
  height: 100vh;
}

#dashboard-content {
  padding-top: 110px;
  height: max-content;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.dashboard-section {
  background-color: #efefef;
  max-width: 450px;
  width: 90%;
  height: 1000px;
  border-radius: 40px;
  display: grid;
  justify-items: center;
}

.dashboard-section-container {
  height: 860px;
}

.dashboard-title-wrapper {
  margin-top: 30px;
  display: flex;
  background-color: white;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  box-shadow: 0px 0px 5px rgb(212, 212, 212);
  width: 400px;
  height: 65px;
}

.dashboard-title {
  font-family: "Cabin";
  font-size: 25px;
  font-weight: 900;
}

.dashboard-card-container {
  display: grid;
  justify-content: center;
}

.dash-card-wrapper {
  margin-top: 20px;
  width: 380px;
  height: 90px;
  background-color: white;
  border-radius: 20px;
  display: flex;
  align-items: center;
  position: relative;
  left: 0px;
  transition: all 0.1s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.dash-card-wrapper:hover {
  border-left-color: #ff7e36;
  border-left-style: solid;
  border-left-width: 5px;
  left: 5px;
}

.dash-card-image {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 50%;
  margin-left: 10px;
  margin-right: 10px;
}

#dash-card-title {
  color: #ff7e36;
  margin-bottom: 5px;
}

#dash-card-desc {
  font-weight: 300;
  max-width: 90%;
  font-size: 15px;
}

#dash-card-creator {
  margin-top: 5px;
  font-size: 10px;
  color: #444444;
}

.dashboard-view-button {
  border-color: #ff7e36;
  padding: 8px 30px;
  height: max-content;
  color: #ff7e36;
  font-family: "Cabin";
  font-weight: 900;
  font-size: 15px;
}

.dashboard-view-button:hover {
  background-color: #ff7e36;
  color: white;
}

.dashboard-view-button:active {
  background-color: #e96620;
  border-color: #e96620;
}

.dashboard-blank {
  margin-top: 360px;
  font-size: 40px;
}

.dashboard-create-button {
  border-color: #ff7e36;
  background-color: #ff7e36;
  padding: 12px 30px;
  height: max-content;
  color: white;
  font-family: "Cabin";
  font-weight: 900;
  font-size: 15px;
  margin-top: 10px;
  line-height: 1;
}

.dashboard-create-button:hover {
  background-color: #eb651e;
  border-color: #eb651e;
}

.dashboard-create-button:active {
  background-color: #bd4d10;
  border-color: #bd4d10;
}
@media screen and (max-width: 1350px) {
  .dashboard-section {
    margin-bottom: 50px;
  }
}
