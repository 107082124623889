.contentOutline {
  border: rgb(216, 216, 216);
  border-style: solid;
  border-width: 2px;
  border-radius: 5px;
  height: max-content;
  display: grid;
  grid-template-columns: repeat(auto-fit, 290px);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  padding: 20px;
}

.browseHeader {
  font-family: Helvetica;
  font-weight: 600;
  font-size: 35px;
  color: rgb(53, 53, 53);
}

#browseWrapper {
  margin-top: 120px;
  display: grid;
  grid-template-columns: 300px 1fr 10px;
  grid-auto-rows: max-content;
  margin-bottom: 100px;
}

#browseFilters {
  grid-row: 1/3;
  grid-column: 1/2;
}

.browserMoreWrapper {
  margin-top: 5px;
  position: absolute;
  right: 10px;
}

.browseMore {
  background-color: rgb(199, 199, 199);
  border: none;
  color: white;
  font-family: Helvetica;
  font-weight: 300;
  line-height: 1;
  padding: 8px;
  border-radius: 4px;
  position: relative;
  bottom: 0px;
  transition: bottom 0.1s;
  border-bottom-style: none;
}

.browseMore:hover {
  border: rgb(141, 141, 141);
  border-width: 1px;
  border-bottom-style: solid;
  bottom: 2px;
  color: white;
  text-decoration: none;
}

#browseFamilyPageContent {
  margin-top: 100px;
  width: 95%;
}

#browseFamilyPageWrapper {
  display: flex;
  justify-content: center;
}

#browseFilter {
  margin-left: 20px;
}
#browseFilterTitle {
  font-family: Helvetica;
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 10px;
}

#browseFilterBar {
  width: 100px;
  height: 1px;
  background-color: rgb(228, 228, 228);
  margin-bottom: 30px;
}

.browseFilterButtonWrapper {
  margin-bottom: 30px;
}

.browseFilterButton {
  font-size: 15px;
  font-family: "Source Sans Pro";
  font-weight: 500;
  color: white;
  background-color: rgb(255, 110, 120);
  padding: 10px 18px;
  border-radius: 3px;
  position: relative;
  bottom: 0px;
  transition: bottom 0.1s;
}

.browseFilterButton:hover {
  color: white;
  bottom: 2px;
  text-decoration: none;
  border: rgb(179, 179, 179);
  border-bottom-style: solid;
  border-width: 1px;
}
