#userWrapper {
  margin-top: 100px;
  width: 100vw;
  height: 100vh;
  display: grid;
  justify-content: center;
}

#userUsername {
  font-size: 80px;
  font-style: "Source Sans Pro";
  font-weight: 900;
  line-height: 1;
  margin-bottom: 10px;
}

.userInfo {
  line-height: 1;
  font-family: "Source Sans Pro";
  font-size: 30px;
  font-weight: 300;
  margin-bottom: 10px;
}

.userInfoHeader {
  font-weight: 100;
}

#userContent {
  display: grid;
  grid-template-columns: 600px 1fr;
  grid-row-gap: 20px;
  grid-template-rows: 200px;
  position: relative;
}

#UserDescriptionContent {
  display: grid;
  justify-content: end;
  margin-right: 100px;
}

.userBlockOutline {
  border: rgb(230, 230, 230);
  border-style: solid;
  border-width: 2px;
  border-radius: 3px;
  max-width: 1000px;
  height: max-content;
}

#userRecipeContent {
  display: grid;
  grid-template-columns: repeat(auto-fit, 290px);
  grid-gap: 10px;
  justify-content: center;
  margin: 20px;
}

#userBlock3 {
  grid-column: 1/3;
  position: relative;
}

@media screen and (max-width: 900px) {
  #userContent {
    grid-template-columns: 400px;
    grid-template-rows: repeat(auto-fit, 150px);
  }
  #userBlock1 {
    grid-row: 1/3;
    grid-column: 1/2;
    margin-bottom: 30px;
  }
  #userBlock2 {
    grid-row: 2/3;
    grid-column: 1/2;
  }
  #userBlock3 {
    grid-column: 1/2;
    grid-row: 3/4;
  }
  #UserDescriptionContent {
    justify-content: start;
  }
}
